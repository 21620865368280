<template>
  <div class="content" v-if="statusTxt && statusTxt != 0" :class="{ green: isGreen }">
    {{ statusTxt }}
  </div>
</template>

<script>
import Tooltip from '@/modules/common/components/Tooltip'
export default {
  props: {
    status: {
      type: String | Number
    },
    isCount: {
      type: Boolean
    },
    isError: {
      type: Boolean
    },
    tooltipTxt: {
      type: String
    }
  },
  data() {
    return {
      green: '#46AE4E',
      yellow: '#FFC24C',
      red: '#FF4C66'
    }
  },

  computed: {
    statusTxt() {
      if (isNaN(this.status) && typeof this.status === 'string') return this.status.charAt(0).toUpperCase() + this.status.slice(1)
      else if (this.isCount) return this.status
      else return this.status + '%'
    },
    borderColor() {
      if (typeof this.statusTxt === 'string' && this.statusTxt.toLowerCase() === 'no') return '#389CEB'
      else if (!this.statusTxt || this.statusTxt == 0) return 'transparent'

      if (this.isError) return this.red
      else if (typeof this.statusTxt === 'string' && typeof this.status === 'string' && this.status.toLowerCase() === 'err') return this.red
      else if ((typeof this.statusTxt === 'string' && typeof this.status === 'string' && (this.status.toLowerCase() === 'empty' || this.status.toLowerCase() === 'open')) || (!this.isCount && this.status <= 15)) return this.yellow
      else return this.green
    },
    isGreen() {
      return this.$route.name === 'device-active'
    }
  },
  components: { Tooltip }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';

.device-status-container {
  width: 2.5vw;
  height: 2.5vw;
  border: 3px solid;
  border-radius: 100%;
  padding: 2px;
  margin: 0 5px;
}

.content {
  color: red;
  @include flex-center;
  font-size: $font-size-tiny;
  font-weight: $font-weight-bold;
}

.green {
  color: green;
}
</style>
