<template>
  <label class="b-contain">
    <input type="checkbox" @input="emitValue" :checked="value" @click="$emit('click')" />
    <div class="b-input"></div>
  </label>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: false },
  },
  methods: {
    emitValue(ev) {
      this.$emit('input', ev.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.54;
  font-size: 1rem;
  font-family: inherit;
}

.b-contain {
  display: table;
  position: relative;
  cursor: pointer;

  .default-shift & {
    margin-block-start: 20px;
  }
}

.b-contain input[type='checkbox'] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: 1px solid rgba(184, 194, 204, 1);
  border-radius: 0.125rem;
}

.b-contain input[type='checkbox'] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: 0.45rem;
  top: 0.18rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid rgba(255, 255, 255, 1);
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input:disabled ~ .b-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
  background: rgb(231, 238, 243);
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgba(52, 144, 220, 0.5);
}

.b-contain input:checked ~ .b-input {
  background: rgba(0, 130, 243, 1);
  border-color: rgba(0, 130, 243, 1);
}

.b-contain input[type='checkbox']:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input:checked:focus ~ .b-input,
.b-contain:hover input:not([disabled]):checked ~ .b-input {
  background: rgba(13, 143, 255, 1);
  border-color: rgba(13, 143, 255, 1);
}

.b-contain .b-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  background: rgba(0, 130, 243, 1);
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.b-contain input + .b-input::before {
  animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
  animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
  visibility: hidden;
}

.b-contain input:focus + .b-input::before {
  visibility: visible;
}

.b-contain:first-child .b-input::before {
  visibility: hidden;
}
</style>
