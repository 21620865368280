<template>
  <div class="battery-container">
    <span class="body" :style="bodyStyle">
      <span>{{percentageTxt}}</span>
    </span>
    <span class="tip" :style="tipStyle"></span>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: String | Number,
    }
  },
  data() {
    return {
      green: '#46AE4E',
      yellow: '#FFC24C',
      red: '#FF4C66'
    }
  },
  computed: {
    percentageTxt() {
      return !isNaN(this.percentage) ? `${this.percentage}%` : this.percentage.charAt(0).toUpperCase() + this.percentage.slice(1)
    },
    bodyStyle() {
      let border = '4px solid '
      if (this.percentage < 10) border += this.red;
      else if (this.percentage < 50) border += this.yellow;
      else border += this.green
      return { border }
    },
    tipStyle() {
      let border = '1px solid '
      let backgroundColor;
      if (this.percentage < 10) {
        border += this.red;
        backgroundColor = this.red;
      } else if (this.percentage < 50) {
        border += this.yellow;
        backgroundColor = this.yellow;
      } else {
        border += this.green
        backgroundColor = this.green;
      }
      return { border, backgroundColor }

    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/vars.scss";
@import "@/styles/mixins.scss";

.battery-container {
  width: 3.5vw;
  height: 3vh;
  display: flex;
  .body {
    border-radius: 3px;
    width: 80%;
    padding: 2px;
    span {
      display: inline-block;
      background-color: #707070;
      color: #fff;
      font-size: $font-size-tiny;
      font-weight: $font-weight-bold;
      @include flex-center;
      width: 100%;
      height: 100%;
    }
  }
  .tip {
    width: 10%;
    height: 50%;
    align-self: center;
    position: relative;
    left: -2px;
  }
}
</style>