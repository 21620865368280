<template>
	<div>
		<md-switch class="ios-switch" v-model="user_flag.isOn" @change="emitClick(user_flag)" :class="getFlagStatus(user_flag.name)"></md-switch>
	</div>
</template>

<script>
import swalService from "@/modules/common/services/swalService"
export default {
	name: 'ios-switch-button',
	props: {
		userFlag: {
			type: Object,
			required: true
		},
		sensorFlag: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			user_flag: { ...this.userFlag },
			sensor_flag: { ...this.sensorFlag }
		}
	},
	methods: {
		getFlagStatus() {
			const { user_flag, sensor_flag } = this
			const isUserFlagOn = user_flag.isOn
			const isSensorFlagOn = sensor_flag.isOn
			if (isUserFlagOn && isSensorFlagOn) return 'succeded md-checked'
			if (isUserFlagOn && !isSensorFlagOn) return 'waiting md-checked'
			if (!isUserFlagOn && isSensorFlagOn) return 'turn-off'
			if (user_flag.name === 'is_maintenance_btn') return 'default-blue-btn'
			else return ''
		},
		async emitClick() {
			const result = await swalService.confirmMsg()
			if (result.value) {
				this.$emit('toggleFlag', this.user_flag)
			} else {
				this.user_flag.isOn = !this.user_flag.isOn
			}
		}
	}
}
</script>

<style lang="scss">
@import '@/styles/mixins.scss';
.waiting.md-checked,
.turn-off {
	.md-switch-container {
		background-color: #fef1d3 !important;
	}
	.md-switch-thumb {
		background-color: #fcc24d !important;
	}
}

.succeded.md-checked {
	.md-switch-container {
		background-color: #cee9d0 !important;
	}
	.md-switch-thumb {
		background-color: #46ae4e !important;
	}
}

.ios-switch {
	&.md-switch {
		margin: 0;
		@include rtl {
			margin-inline-start: 16px;
		}
		&.default-blue-btn {
			.md-switch-container {
				background-color: #389CEB;
			}
		}
		.md-switch-container {
			.md-switch-thumb {
				&::before {
					width: 0;
					height: 0;
				}
				.md-ripple {
					width: 0 !important;
					height: 0 !important;
				}
			}
		}
		&.md-checked {
			.md-switch-thumb {
				@include rtl {
					transform: translate3d(-15px, 0, 0);
				}
			}
		}
	}
}
</style>
