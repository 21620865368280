<template>
  <div class="tooltip">
    <slot />
    <div class="top">
      <div :class="texts.length ? 'txts-container' : 'txt-container'">
        <div class="bold-tooltip" v-if="boldText">{{ $t(boldText) }} /</div>
        <template v-if="texts.length">
          <div v-for="(text, idx) in texts" :key="text + idx" :class="isCapitalized ? 'capitalize' : ''">
            {{ $t(text) }}
          </div>
        </template>
        <div v-else class="tooltip-text">{{ $t(text) }}</div>
      </div>
      <i></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: { required: false, type: [String, Number] },
    boldText: { required: false, type: String },
    texts: { required: false, type: Array, default: () => [] },
    isCapitalized: { required: false, type: Boolean, default: true },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
@import '@/styles/mixins.scss';

.tooltip {
  display: inline-block;
  position: relative;
  text-align: left;
}

.txts-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.txt-container {
  @include flex-center;
  .tooltip-text {
    white-space: pre-line;
  }
}

.bold-tooltip {
  font-weight: $font-weight-bold;
}

.tooltip .top {
  top: -10px;
  left: 5%;
  @include rtl {
    left: 30%;
  }
  min-width: max-content;
  transform: translate(-30%, -100%);
  padding: 10px;
  color: $black-blue;
  background-color: $light-grey;
  font-weight: normal;
  font-size: $font-size-smaller;
  border-radius: 8px;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 100;
}

.tooltip:hover .top {
  display: block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 30%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  left: 60%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: $light-grey;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.capitalize {
  // text-transform: capitalize;
}

</style>
