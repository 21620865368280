<template>
	<div class="icon-container">
		<button v-if="isRestartBtn" @click="toggleFlag">
			<svg id="Component_139_1" data-name="Component 139 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 19 19">
				<defs>
					<clipPath id="clip-path">
						<rect id="Rectangle_2220" data-name="Rectangle 2220" width="19" height="19" transform="translate(0 -0.331)" fill="none" />
					</clipPath>
				</defs>
				<g id="Group_4034" data-name="Group 4034" transform="translate(0 0.331)" clip-path="url(#clip-path)">
					<path
						id="Path_11562"
						data-name="Path 11562"
						d="M15.961,0H2.708A2.708,2.708,0,0,0,0,2.707V15.96a2.708,2.708,0,0,0,2.708,2.708H15.961a2.708,2.708,0,0,0,2.708-2.708V2.707A2.708,2.708,0,0,0,15.961,0M9.317,14.688a5.356,5.356,0,0,1-4.909-3.213h1.2A4.285,4.285,0,1,0,5.864,6.8l.4.156a.327.327,0,0,1,.12.528L4.564,9.436A.327.327,0,0,1,4,9.213V6.545a.327.327,0,0,1,.447-.3l.4.156a5.355,5.355,0,1,1,4.477,8.292"
						transform="translate(0 0.001)"
						:fill="backgroundColor"
					/>
				</g>
			</svg>
		</button>
		<button v-if="isUpdateBtn" @click="toggleFlag">
			<svg id="Component_138_1" data-name="Component 138 – 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="19" height="19" viewBox="0 0 19 19">
				<defs>
					<clipPath id="clip-path">
						<rect id="Rectangle_2219" data-name="Rectangle 2219" width="19" height="19" fill="none" />
					</clipPath>
				</defs>
				<g id="Group_4032" data-name="Group 4032" clip-path="url(#clip-path)">
					<path
						id="Path_11561"
						data-name="Path 11561"
						d="M16.244,0H2.756A2.756,2.756,0,0,0,0,2.756V16.244A2.756,2.756,0,0,0,2.756,19H16.244A2.756,2.756,0,0,0,19,16.244V2.756A2.756,2.756,0,0,0,16.244,0M4.068,9.02V6.238a.342.342,0,0,1,.466-.318l.5.195a5.585,5.585,0,0,1,9.56,1.152H13.34a4.468,4.468,0,0,0-7.219-.722l.308.121a.342.342,0,0,1,.125.55L4.659,9.252a.341.341,0,0,1-.591-.232m10.864,3.6a.341.341,0,0,1-.466.318L14,12.761A5.586,5.586,0,0,1,4.35,11.734H5.6a4.468,4.468,0,0,0,7.322.6l-.352-.138a.341.341,0,0,1-.125-.55L14.341,9.61a.341.341,0,0,1,.591.233Z"
						:fill="backgroundColor"
					/>
				</g>
			</svg>
		</button>
	</div>
</template>

<script>
import swalService from "@/modules/common/services/swalService"
export default {
	name: 'restart-button',
	props: {
		flag: {
			type: Object,
			required: true
		},
		isUpdateBtn: {
			type: Boolean,
			default: false
		},
		isRestartBtn: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			flagInfo: { ...this.flag }
		}
	},
	methods: {
		async toggleFlag() {
			this.flagInfo.isOn = !this.flagInfo.isOn
			const result = await swalService.confirmMsg()
			if (result.value) {
				this.$emit('toggleFlag', this.flagInfo)
			} else {
				this.flagInfo.isOn = !this.flagInfo.isOn
			}
		}
	},
	computed: {
		backgroundColor() {
			if (this.isUpdateBtn) return this.flagInfo.isOn ? '#ffc24c' : '#937392'
			else return this.flagInfo.isOn ? '#ffc24c' : '#4d4d4d'
		}
	}
}
</script>
